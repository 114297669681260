import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi everyone, I am <span className="purple">Saksham Karnawat </span>
            from <span className="purple"> Gurgaon, India</span>
            <br /> I am a passionate and driven Flutter Developer with 2+ years of professional experience in building mobile applications for iOS and Android
            <br />
            <br />
            I enjoy connecting the dots: be it ideas from different disciplines, people from different teams, or applications from different industries.
            <br />
            I am skilled in building robust mobile applications with a focus on delivering seamless user experiences. Passionate about leveraging cutting-edge technologies to drive innovation and create user-centric solutions.
            <br />
            <br />
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "The best error message is the one that never shows up."{" "}
          </p>
          <footer className="blockquote-footer">Thomas Fuchs</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

// TODO: Fix typewriter to make it animate only once per page load and text stays after animation

// TODO: Also fix the keywords, about section etc.

// TODO: Add BuyMeACoffee widget
{/* <script data-name="BMC-Widget" data-cfasync="false" src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js" data-id="sakshamkarnawat" data-description="Support me on Buy me a coffee!" data-message="" data-color="#BD5FFF" data-position="Right" data-x_margin="18" data-y_margin="18"></script> */ }


// TODO: Add backlink to soumyajit's profile:
// https://github.com/soumyajit4419/Portfolio