import React from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiSwift,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiPostgresql,
  SiHtml5,
  SiFlutter,
  SiSwift,
  SiDart,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiFlutter />
        <p className="icon-desc">Flutter</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDart />
        <p className="icon-desc">Dart</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <p className="icon-desc">Firebase</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <p className="icon-desc">Python</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <p className="icon-desc">Java</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <p className="icon-desc">MongoDB</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons" onMouseOver={() => console.log("abc")}>
        <DiJavascript1 />
        <p className="icon-desc">JavaScript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <p className="icon-desc">React.js</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <p className="icon-desc">Node.js</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHtml5 />
        <p className="icon-desc">HTML</p>
      </Col>
      {/* <Col xs={4} md={2} className="tech-icons">
        <DiSwift />
        <p className="icon-desc">Swift</p>
      </Col> */}
      {/* <Col xs={4} md={2} className="tech-icons">
        <SiSolidity />
      </Col> */}

      {/* <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col> */}
      {/* <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col> */}
      {/* <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col> */}
    </Row>
  );
}

export default Techstack;
