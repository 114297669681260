import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import Particle from "../Particle";
import nexus from "../../Assets/Projects/nexus.png";
import clima from "../../Assets/Projects/clima5.png";
import pokedex from "../../Assets/Projects/pokedex.png";
import outpost from "../../Assets/Projects/outpost.png";

function Projects(props) {
  props.activeTab("projects");
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nexus}
              isBlog={false}
              title="Nexus"
              description="Nexus is an animation-rich, AI-enabled voice assistant app that integrates ChatGPT and Dall-E to generate output based on user input. The app converts user's voice input using Speech-to-Text, and then uses AI to generate a response. The response is then converted to speech using Text-to-Speech."
              ghLink="https://github.com/SakshamKarnawat/nexus-flutter/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pokedex}
              isBlog={false}
              title="Pokédex"
              description="Pokédex is a Flutter application that allows you to see a list of Pokémon and view their details. It uses the PokéAPI to get the data. The app is built using the BLoC architecture and uses the flutter_bloc package to manage the state. The app also uses the http package to make API calls."
              ghLink="https://github.com/SakshamKarnawat/pokedex-flutter/"
            // demoLink="ttps://raw.githubusercontent.com/SakshamKarnawat/SakshamKarnawat/main/pokedex_demo.gif"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={outpost}
              isBlog={false}
              title="Outpost"
              description="Outpost is a next-gen UI that showcases Flutter's capabilities to build beautiful UIs."
              ghLink="https://github.com/SakshamKarnawat/flutter-outpost"
              demoLink="https://outpost-6a427.web.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={clima}
              isBlog={false}
              title="Clima"
              description="Clima is a weather app developed using Flutter that seamlessly combines simplicity and functionality to provide the weather data of the current location as well as any city in the world, using the OpenWeatherMap API. The app uses the Geolocator package to fetch the current location of the user."
              ghLink="https://github.com/SakshamKarnawat/Flutter-Clima"
              demoLink="https://raw.githubusercontent.com/SakshamKarnawat/SakshamKarnawat/main/clima_demo.gif"
            // storeLink="https://play.google.com/store/apps/details?id=com.sakshamkarnawat.clima"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
